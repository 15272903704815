import { FunctionComponent, useRef, useState } from 'react'
import { Button, Card, CardContent, Box, CardActions } from '@mui/material'
import { ChecklistDocument } from '@black-bear-energy/black-bear-energy-common'
import { Variant } from '../../common/types'
import { useApiWithIDTokenGranted } from '../../state/modules/common'
import { IconLabel } from './IconLabel'
import { useNotifications } from '../../state/modules/notifications'

/**
 * A card that displays a checklist document.
 * An icon indicates whether the document is uploaded.
 * A warning status is displayed if the document is required and not uploaded
 * or uploaded but not approved.
 * A success status is displayed if the document is uploaded and approved.
 * The user can upload, delete, and restore a file associated with the document.
 * @param checklistDocument - The checklist document to display.
 * @returns A card that displays a checklist document.
 */
export const ChecklistDocumentCard: FunctionComponent<
  ChecklistDocumentCardProps
> = ({ checklistDocument }: ChecklistDocumentCardProps) => {
  const [isDragging, setIsDragging] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [isUploaded, setIsUploaded] = useState(checklistDocument.uploaded)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isDeleted, setIsDeleted] = useState(checklistDocument.deleted)
  const [isRestoring, setIsRestoring] = useState(false)
  const [isApproved, setIsApproved] = useState(
    checklistDocument.approvedBy !== null && !isDeleted
  )
  // A document is replaced if the user has uploaded a new version since loading the page.
  const [isReplaced, setIsReplaced] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const api = useApiWithIDTokenGranted()
  const { send: sendNotification } = useNotifications()

  /**
   * Set the dragging state to true when the user drags over the document card.
   * @param event - The drag and drop event.
   */
  const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()

    if (event.type === 'dragenter' || event.type === 'dragover') {
      setIsDragging(true)
    } else if (event.type === 'dragleave') {
      setIsDragging(false)
    }
  }

  /**
   * Display a toast notification using the document name as the title.
   * @param message - The notification message.
   * @param variant - The variant, which determines the color of the notification.
   */
  const sendDocumentNotification = (message: string, variant: Variant) => {
    sendNotification({
      title: checklistDocument.name,
      message,
      variant,
    })
  }

  /**
   * Upload a file for the checklist document.
   * @param file - The file to upload.
   */
  const uploadFile = async (file: File) => {
    setIsUploading(true)
    sendDocumentNotification(`Uploading ${file.name}`, Variant.INFO)
    try {
      if (!api) {
        return
      }

      const url = `/documents/${checklistDocument.id}`
      const data = new FormData()
      data.append('file', file)
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const response = await api.put<{ fileName: string }>(url, data, config)
      const { fileName } = response.data
      sendDocumentNotification(`Uploaded ${fileName}`, Variant.SUCCESS)
      setIsReplaced(true)
      setIsUploaded(true)
      setIsDeleted(false)
      setIsApproved(false)
    } catch (error) {
      const errorMessage = (error as Error).toString()
      const message = `Failed to upload ${file.name}: ${errorMessage}`
      sendDocumentNotification(message, Variant.DANGER)
    } finally {
      fileInputRef.current!.value = ''
      setIsUploading(false)
    }
  }

  /**
   * Delete the file for the checklist document.
   */
  const deleteFile = async () => {
    setIsDeleting(true)
    sendDocumentNotification(`Deleting ${checklistDocument.name}`, Variant.INFO)
    try {
      if (!api) {
        return
      }

      const url = `/documents/${checklistDocument.id}/delete`
      await api.put<{ fileName: string }>(url)
      sendDocumentNotification(
        `Deleted ${checklistDocument.name}`,
        Variant.SUCCESS
      )
      setIsUploaded(false)
      setIsDeleted(true)
      setIsApproved(false)
    } catch (error) {
      const errorMessage = (error as Error).toString()
      const message = `Failed to delete ${checklistDocument.name}: ${errorMessage}`
      sendDocumentNotification(message, Variant.DANGER)
    } finally {
      setIsDeleting(false)
    }
  }

  /**
   * Restore a deleted file for the checklist document.
   */
  const restoreFile = async () => {
    setIsRestoring(true)
    sendDocumentNotification(
      `Restoring ${checklistDocument.name}`,
      Variant.INFO
    )
    try {
      if (!api) {
        return
      }

      const url = `/documents/${checklistDocument.id}/restore`
      await api.put<{ fileName: string }>(url)
      sendDocumentNotification(
        `Restored ${checklistDocument.name}`,
        Variant.SUCCESS
      )
      setIsUploaded(true)
      setIsDeleted(false)
      setIsApproved(checklistDocument.approvedBy !== null && !isReplaced)
    } catch (error) {
      const errorMessage = (error as Error).toString()
      const message = `Failed to restore ${checklistDocument.name}: ${errorMessage}`
      sendDocumentNotification(message, Variant.DANGER)
    } finally {
      setIsRestoring(false)
    }
  }

  /**
   * When the user drops a file, upload it.
   * @param event - The drop event.
   */
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setIsDragging(false)
    if (event.dataTransfer.files.length !== 1) {
      sendDocumentNotification('More than one file dropped', Variant.DANGER)
      return
    }

    const file = event.dataTransfer.files[0]
    void uploadFile(file)
  }

  /**
   * When the user selects a file, upload it.
   * @param event - The change event.
   */
  const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (!files || !files.length) {
      return
    }

    const file = files[0]
    void uploadFile(file)
  }

  /**
   * When the user clicks the upload button, open the file input.
   */
  const handleUploadClick = () => {
    fileInputRef.current?.click()
  }

  const uploadedIconName = isUploaded ? 'Task' : 'InsertDriveFileOutlined'
  const fileInputId = `checklistDocumentCardFileInput${checklistDocument.id}`

  return (
    <Card
      data-testid="checklistDocumentCard"
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      variant="outlined"
      sx={{
        my: 2,
        borderColor: isDragging ? 'primary.main' : '#cccccc',
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          '&:last-child': { pb: 2 },
        }}
      >
        <IconLabel iconName={uploadedIconName} label={checklistDocument.name} />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ mx: 3 }}>
            {checklistDocument.required && !isUploaded && (
              <IconLabel iconName="Error" label="Required" />
            )}
            {isUploaded && !isApproved && (
              <IconLabel iconName="Error" label="Needs Approval" />
            )}
            {isApproved && (
              <IconLabel
                iconName="CheckCircle"
                label={`Approved by ${checklistDocument.approvedBy}`}
              />
            )}
          </Box>
        </Box>
      </CardContent>
      <CardActions>
        <Button onClick={handleUploadClick} disabled={isUploading}>
          Upload
        </Button>
        <Box
          component="input"
          ref={fileInputRef}
          id={fileInputId}
          data-testid={fileInputId}
          type="file"
          onChange={handleFileInput}
          sx={{ display: 'none' }}
        />
        {isUploaded && (
          <Button onClick={deleteFile} disabled={isDeleting}>
            Delete
          </Button>
        )}
        {isDeleted && (
          <Button onClick={restoreFile} disabled={isRestoring}>
            Restore
          </Button>
        )}
      </CardActions>
    </Card>
  )
}

export interface ChecklistDocumentCardProps {
  checklistDocument: ChecklistDocument
}
